import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './PAGES/Home/Home';
import Reservation from './PAGES/Reservation/Reservation';
import Login from './PAGES/Login/Login';
import Payment from './PAGES/Payment/Index';
import Profil from './PAGES/Profil/Profil';
import AuthGuard from './AuthGuard'
import { CartProvider } from './CartContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ForgotPassword from './PAGES/Login/ForgotPassword';
import NewPassword from './PAGES/Login/NewPassword';
import Document from './PAGES/Documents/document';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

if (!stripePublicKey) {
  throw new Error('REACT_APP_STRIPE_PUBLIC_KEY is not defined');
}

const stripePromise = loadStripe(stripePublicKey);
function App() {
  return (
   <BrowserRouter>
   <CartProvider>
    <Routes>
    
          <Route path='/' element={<AuthGuard><Elements stripe={stripePromise}><Home/></Elements> </AuthGuard>} />

          {/* <Route path="/" element={<AuthGuard> <Home /> </AuthGuard>} /> */}
          <Route path="/payment" element={<AuthGuard> <Payment /> </AuthGuard>} />
          <Route path='/reservation' element={<AuthGuard><Reservation/></AuthGuard>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/profil' element={<AuthGuard><Profil/></AuthGuard>} />
          <Route path='/forgotPassword' element={<ForgotPassword/>} ></Route>
          <Route path='/new-password' element={<NewPassword/>} ></Route>
          <Route path='/document' element={<Document/>} ></Route>
          {/* <Route path='/logout' /> */}
    </Routes>
   </CartProvider>
   </BrowserRouter>
  );
}

export default App;
