import axios from "axios";

export const newPassword = async (passwords)=>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/new-password`,passwords)
        return response.data;
    }catch(error){
        console.log("Erreur lors de l'appel a l'API ",error)
        throw error
    }
}