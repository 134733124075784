import restoImage from "../../ASSETS/Image/top-view-cooked-chicken-spiced-up-with-potatoes-dark-surface.jpg";
import { FaUserLarge } from "react-icons/fa6";
import { IoIosLock } from "react-icons/io";
import Loading from '../../COMPONENTS/Loading';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logo } from "../../API/Logo/logo";
import { newPassword } from "../../API/login/newPassword";
import { useNavigate } from "react-router-dom";

function NewPassword() {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(true);
      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);

      useEffect(()=>{
         if(JSON.parse(localStorage.getItem('email')) === null){
             navigate('/forgotPassword')
         }
      })
     
      const [credentials,setCredentials] = useState({
        pass:'',
        passconfirm:'',
        email:JSON.parse(localStorage.getItem('email'))
       
      })
    
      const onChange = (e)=>{
             setCredentials({
              ...credentials,
                 [e.target.name]:e.target.value
             })
      } 
      const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])
     
     const [errors,setErrors] = useState({})
      const onSubmit = async (e) => {
        e.preventDefault();
        console.log(credentials)
        try{
            if(credentials.pass !== credentials.passconfirm){
              toast.error('Les deux mots de passe ne sont pas identique');
            }else{
              const response = await newPassword(credentials);
              if(response.status === 422) {
                const validationErrors = response.errors;
                setErrors(validationErrors);
            
                if (typeof validationErrors === 'string') {
                    toast.error(validationErrors);
                } else {
                    for (const key in validationErrors) {
                        if (validationErrors.hasOwnProperty(key)) {
                            const error = validationErrors[key];
                            if (Array.isArray(error)) {
                                error.forEach(errMsg => {
                                    toast.error(errMsg);
                                });
                            } else {
                                toast.error(error);
                            }
                        }
                    }
                }
            }
            
              if(response.status === 200){
                toast.success(response.message)
                localStorage.removeItem('email')
                let mdp = 'status'
                localStorage.setItem('mdp',mdp)
                navigate('/login')
              }
              if(response.status === 500){
                toast.error(response.message)
              }
            }
        }catch(error){
          console.log("Erreur lors de l'appel ",error)
        }
      };
      
  return (
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
      <div
      className="bg-center bg-cover bg-no-repeat h-screen flex justify-end"
      style={{ backgroundImage: `url(${restoImage})` }}
    > 
      <form onSubmit={onSubmit} className="bg-white w-[600px] flex flex-col space-y-7 text-lg items-center justify-center ">
        <div className="text-4xl font-bold"><img className="w-40 h-40 rounded-full object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/></div>
        <div className="text-2xl font-bold">ON-SITE</div>
        <div className="font-semibold">Reinitialisation du mot de passe</div>
        <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px]  flex flex-row items-center px-3">
            <IoIosLock className="text-[#a5a5a5] text-xl" />
            <input
                type="password"
                value={credentials.pass}
                name="pass"
                onChange={onChange}
                className=" h-12 px-3 w-[370px] bg-transparent outline-none text-base"
                placeholder="Nouveau mot de passe"
            />
        </div>
        {errors.pass && <p className="text-red-500 text-xs mt-1">{errors.pass}</p> }
        <ToastContainer />
        <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px] flex flex-row items-center px-3">
            <IoIosLock className="text-[#a5a5a5] text-xl" />
            <input
                type="password"
                value={credentials.passconfirm}
                name="passconfirm"
                onChange={onChange}
                className=" h-12 px-3 w-[370px] bg-transparent outline-none text-base"
                placeholder="Confirmer le mot de passe"
            />
        </div>
        {errors.passconfirm && <p className="text-red-500 text-xs mt-1">{errors.passconfirm}</p>}
        <button className="bg-[#D19E06] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white hover:text-[#D19E06] s:w-[400px] xs:w-[240px] sm:w-[300px] text-white h-12">Reinitialiser</button>
        {/* <Link to='/' className="flex hover:text-[#D19E06] items-center space-x-2 text-sm">
          <IoReturnUpBack className="text-xl" />
          <div>Retour a la page d'accueil</div>
        </Link> */}
      </form>
      </div>
      </>
      )}
    </div>
   
  );
}

export default NewPassword;
