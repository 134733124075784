import React, { useState, useEffect, useContext } from 'react';
import plat1 from '../../ASSETS/Image/mexican-tacos-with-beef-tomato-sauce-salsa.png'
import { IoIosTrash } from "react-icons/io";
import { FaRegCreditCard, FaUser } from "react-icons/fa6";
import Food from './Food'
import Loading from '../../COMPONENTS/Loading';
import Header from '../../COMPONENTS/Header';
import { CartContext } from '../../CartContext';
import { taxe } from '../../API/Taxe/taxe';
import { home } from '../../API/Home/home';
import { toast, ToastContainer } from 'react-toastify';

import { paiement } from '../../API/Paiement/paiement';
import { relevate } from '../../API/Home/relevate';
import PaymentPopup from './Payement';
import { add } from '../../API/Reservation/add';
import { useCustomEventListener } from 'react-custom-events';
import { useLocation, useNavigate } from 'react-router-dom';

function Home() {
  const formatNumberWithSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

   const [person,setPerson] = useState('');
   const [isModalOpen,setIsModalOpen] = useState(false);
   const [isModal,setIsModal] = useState(false);
   const [entre, setEntre] = useState(false);
   const [part,setPart] = useState(true);
   const [device,setDevice] = useState();
   const [panier,setPanier] = useState(false);
   const [closePanier,setClosePanier] = useState(false);

  useEffect(() => {
    home().then((response) => {
      if(response)
      {
        setDevice(response.device);
        const id = JSON.stringify(response.device.id_currency);  // Utiliser response.device.id directement
        localStorage.setItem('idCurrency', id);      
      }
    });
  }, []);

  const { cart, addToCart, updateQuantity, decreaseQuantity,removeProduct,calculateTotal,clearCart} = useContext(CartContext);    // compteur, nombre de commande
 
  const handleDecrease = (prod) => {
        decreaseQuantity(prod.id)
    };

    const handleIncrease = (prod) => {
        addToCart(prod)
    };

    const handleChange = (e,prod) => {
      updateQuantity(prod.id, parseInt(e.target.value, 10));
    };
    const remove = (prod)=>{
      removeProduct(prod)
    }

    const location = useLocation();
    useEffect(() => {
      const searchReservate = new URLSearchParams(location.search);
      const idReservation = searchReservate.get('q');
    
      if (cart.length === 0) {
        if (idReservation) {
          setPerson(idReservation);
          setPanier(true);
        } else {
          setPanier(false);
          setClosePanier(true);
        }
      } else {
        setPanier(true);
        setClosePanier(false);
      }
    }, [cart,location.search]);
    const total = calculateTotal();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [taxeValue,setTaxeValue] = useState(0);
    const [alltaxe,setAllTaxe] = useState([]);
    if(localStorage.getItem('status'))
    {
      toast.success('Commande enregistrer avec succès');
      clearCart([]);
      localStorage.removeItem('status')
    }
    useEffect(()=>{
          const fetchTaxe =  async()=>{
              try{
                  const response = await taxe();
                  if(response.somme && response.taxes){
                    setTaxeValue(response.somme);
                    setAllTaxe(response.taxes)
                  }
              }catch(error){
                  console.log("Erreur lots de chargement ",error)
              }
          };
          fetchTaxe();
    },[])
    useEffect(() => {
      const timer = setTimeout(() => {
        // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
        setLoading(false);
      }, 2000); // 5000 millisecondes = 5 secondes

      // Nettoyer le timer si le composant est démonté avant la fin du délai
      return () => clearTimeout(timer);
    }, []);
    let user = JSON.parse(localStorage.getItem('id'));
   const totaux = total + taxeValue * total;
   const navigate  = useNavigate();
    const handlePaimentSubmit = async () => {
        const paiementDetails = {
           cart,
           total,
           totaux,
           person,
           filtered,
           user,
           alltaxe
        }
        if(cart.length === 0)
        {
          //setIsModal(false);
          return toast.error("Votre panier est vide")
        }
        if(person === '')
        {
         // setIsModal(false);
          return toast.error("Veuillez choisie un client ")
        }
        const paiementDetailJson = JSON.stringify(paiementDetails)
       // const id = JSON.stringify(idCurrency);
        localStorage.setItem('paiementDetails',paiementDetailJson)
       // localStorage.setItem('idCurrency',id);
        navigate('/payment')
    };
    const onClosed = ()=>{
      setIsModal(false)
    }
    const handlePopupSuccess = () => {
      setShowPopup(false);
     submitForm();
    };

    useEffect(() => {
      // Récupérer les paramètres de l'URL
      const searchParams = new URLSearchParams(window.location.search);
      
      // Récupérer les valeurs des paramètres
      const paymentId = searchParams.get('paymentId') || searchParams.get('payment_intent');
      const provider = searchParams.get('provider') || searchParams.get('payment_intent_client_secret');
      const idCurrency = searchParams.get('id_currency') || searchParams.get('redirect_status');
      
      // Vérifier si paymentId existe et déclencher une action
      if (paymentId) {
        // Vous pouvez vérifier si le paiement est réussi et ensuite enregistrer les données        
        submitForm(paymentId, provider, idCurrency);
      }
    }, []);
    const submitForm = async (paymentId, provider, idCurrency) => {
      try {
        const paiementStore = JSON.parse(localStorage.getItem('paiementDetails'));
        if (!paiementStore) {
          throw new Error("Les détails du paiement ne sont pas disponibles.");
        }
        
        const paiementDetails = {
          cart: paiementStore.cart,
          total: paiementStore.total || 0,
          totaux: paiementStore.totaux || 0,
          person: paiementStore.person,
          user: paiementStore.user,
          paymentId,
          provider,
          idCurrency,
          filtered: paiementStore.filtered || null,
          alltaxe:paiementStore.alltaxe
          
        };
        console.log(paiementDetails)
        const response = await paiement(paiementDetails);
        if (response.ok) {
          toast.success("Commande enregistrée avec succès");
          localStorage.removeItem('paiementDetails');
          //localStorage.removeItem('idCurrency');
          clearCart([]);
        } else {
          toast.error("Erreur lors du paiement");
        }
      } catch (error) {
        console.error("Erreur lors de l'envoi des données du paiement", error);
      }
    };
    const [releve,setReleve] = useState([]);
    useEffect(() => {
      const fetchReleves = async () => {
          try {
              const sallers = await relevate() ;
              if (sallers.releves) {
                  setReleve(sallers.releves);
              }
          } catch (error) {
              console.log("Erreur lors de la récupération ", error);
          }
      };

      fetchReleves();
  }, []);
  const filtered = releve.find(perso => Number(perso.Customers_Numbers) === Number(person));
   const [formModal,setFormModal] = useState({
    nom: "",
    prenom: "",
    mail: "",
    tel: "",
    compte:""
  });

      const handelFormModalChange = (e)=>{
        const {name,value} = e.target;
        setFormModal((prevFormModalData)=>({
          ...prevFormModalData,
          [name]:value || ''
        }))
    }

  const handeClickP = ()=>{
    setIsModalOpen(true);
  }
  const handleCloseModal = ()=>{
    setIsModalOpen(false)
  }

  const handlEntreChange = (e)=>{
    const {value} = e.target;
    setFormModal((prevFormDatas)=>({
      ...prevFormDatas,
        compte:value
    }));
    setEntre(true);
    setPart(false);
 }
 const handlePartChange = (e)=>{
  const {name,value} = e.target;
  setFormModal((prevFormDatas)=>({
    ...prevFormDatas,
    [name]:value
  }))
  setPart(true);
  setEntre(false)
 }
   useCustomEventListener('customerAdded',(data)=>{
    setReleve((rel)=>[...rel,data])
   })
  const [errorModal,setErrorModal]  = useState({});
    const handleModalFormSubmit = async (e) => {
      e.preventDefault();
    
      try {
           await add(formModal);
         
          setFormModal({
            nom: '',
            prenom: '',
            mail: '',
            tel: '',
            compte: ''
          });
       
        toast.success('Ajout effectué avec succès');
        setIsModalOpen(false);
        setErrorModal({});
      } catch (error) {
        if(error.response && error.response.data.errors){
            const errors = error.response.data.errors;
            setErrorModal(errors);
            // Afficher chaque erreur individuellement dans le toast
            if (typeof errors === 'string') {
              toast.error(errors);
            } else {
              for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                  if (Array.isArray(errors[key])) {
                    errors[key].forEach((errMsg) => {
                      toast.error(errMsg);
                    });
                  } else {
                    toast.error(errors[key]);
                  }
                }
              }
            }
        }
      }
    };

  return (
   <div>
     {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
        <div className='flex flex-row bg-[#F7F7F7] h-screen' >
          {/* Section gauche */}
          <div className='w-2/3 barre_scroll ' >
            {/* Navbar */}
            <Food/>
          </div>

          {/* Section droite */}

           {/* Section droite */}
           <div className='w-1/3 bg-white barre_scroll '>
              <Header></Header>
               <ToastContainer></ToastContainer>
                 {panier && (
                      <div>
                         <div className='md:mt-24 xs:mt-80  pl-6 xs:text-lg md:text-xl '>Mes commandes</div>
                        {/* Personnes */}
                        <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2 ml-3'>
                            <FaUser className='text-xl text-[#616161]' />
                            <select
                              className='w-[300px] px-2 h-10 outline-none'
                              name="person"
                              value={person}
                              onChange={(e)=>setPerson(e.target.value)}
                            >
                              <option value="">choisir le client</option>
                              {
                                releve.map((rel,index)=>(
                                  <option 
                                     key={index}
                                     value={rel.Customers_Numbers}
                                     >
                                      {rel.Names} {rel.LastName}
                                  </option>
                                ))
                              }
                            </select>
                            <p onClick={handeClickP} >
                              <svg className="w-[26px] h-[26px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clipRule="evenodd" />
                              </svg>
                            </p>
                          </div>

                        <div className=' py-6 divide-y-2'>
                            {/* article */}
                            {cart && cart.map((prod, index) => (
                                <div key={index} className='flex xs:flex-col md:flex-row md:items-center md:space-x-4 hover:bg-slate-50 s:px-6 xs:px-3 py-3'>
                                    {/* Nom et categorie de l'article */}
                                    <div>
                                    <img src={prod.img ? `data:image/png;base64,${prod.img}` : plat1} alt='plat1' className='w-[120px] h-[70px]' />
                                    <div className='text-sm font-medium text-[#D19E06]'>{prod.Names}</div>
                                    <div className='text-xs'>{prod.categorie}</div>
                                    </div>

                                    {/* quantity */}
                                    <div className='flex items-center text-sm justify-center space-x-4'>
                                    <button
                                        className="focus:outline-none"
                                        onClick={()=>handleDecrease(prod)}
                                    >
                                        -
                                    </button>
                                    <input type='number' value={prod.quantity} onChange={(e)=>handleChange(e,prod)} className='w-[40px] px-2' min="1" />
                                    <button
                                        className="focus:outline-none"
                                        onClick={()=>handleIncrease(prod)}
                                    >
                                        +
                                    </button>
                                    </div>

                                    {/* prix */}
                                    <div className='text-sm font-medium'>{formatNumberWithSpaces(prod.price * prod.quantity)} {device && device.sign}</div>

                                    <IoIosTrash onClick={()=>remove(prod.id)} className='text-2xl text-[#D80E0E]' />
                                </div>
                                ))
                            }
                        </div>

                        {/* Prix total */}
                        <div className='border-t-2 pt-4 barre_scroll2'>
                            <table className='text-lg  '>
                              <tbody>
                              <tr>
                                <td>Total de la commande:</td>
                                <td className="ml-8">{formatNumberWithSpaces(total)}</td>
                                <td className="ml-8">
                                   {device && device.sign}
                                </td>
                              </tr>

                                <tr>
                                    <td>Les taxes:</td>
                                    <td>{formatNumberWithSpaces(taxeValue * total) }</td>
                                    <td className="ml-60">
                                      {device && device.sign}
                                    </td>
                                </tr>
                                <tr className="font-bold">
                                    <td >TOTAUX:</td>
                                    <td>{formatNumberWithSpaces(total + taxeValue * total)}</td>
                                    <td>
                                        {device && device.sign}
                                    </td>
                                </tr>
                              </tbody>

                            </table>
                        </div>

                      <div className='flex justify-center my-4'>
                          <button onClick={handlePaimentSubmit}  className='bg-[#D19E06] text-white flex items-center space-x-3 text-xl w-[150px] h-10 justify-center'>
                              <FaRegCreditCard />
                              <p>Paiement</p>
                          </button>
                          {/* <Link to='/payment' className='bg-[#D19E06] text-white flex items-center space-x-3 text-xl w-[150px] h-10 justify-center'>
                              <FaRegCreditCard />
                              <p>Paiement</p>
                          </Link> */}
                      </div>
                      </div>
                   )  
                 }
                 {closePanier && (
                      <p> Panier vide </p>
                  )}
                   {isModal &&
                   <PaymentPopup open={isModal}
                   tel ={filtered.Phones}
                   user_Id={filtered.Customers_Numbers} 
                   montant  = {totaux}
                   email  = {filtered['E-mails']}
                   onSuccess={handlePopupSuccess} 
                   userPrenomu={filtered.LastName} 
                   serNom={filtered.Names} 
                   onClose={onClosed}
                   handlCashSubmit={submitForm}
                   />}
                          
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                  <div className="bg-white p-8 rounded shadow-lg">
                    <h2 className="text-xl mb-4">Veuillez remplir le formulaire</h2>
                           <form onSubmit={handleModalFormSubmit}>
                                <div className="mt-2 mb-2 d-flex ml-2">
                                    <p>
                                      Type:
                                        <input onChange={handelFormModalChange} onClick={handlEntreChange}   className="ml-2 mt-2" type="radio" name="compte" value="webcompany" id="compte" />
                                        <label  htmlFor="compte" className="flex-1 ml-2">Entreprise</label>
                                        <input onChange={handelFormModalChange} onClick={handlePartChange}  checked className="mt-2 ml-3"  type="radio" name="compte" value="webbyyer" id="compte"/>
                                        <label  htmlFor="compte" className="flex-1 ml-2">Particulier</label>
                                    </p>
                                    {errorModal.compte && <p className="text-red-500 text-xs mt-1">{errorModal.compte}</p>}
                                </div>
                                <div >
                                  {part && (
                                    <>
                                      <div className='mb-4 border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                                        <input
                                          type='text'
                                          className='w-[340px] px-2 h-10 outline-none'
                                          placeholder='Nom'
                                          name="nom"
                                          value={formModal.nom}
                                          onChange={handelFormModalChange}
                                        />
                                      </div>
                                      {errorModal.nom && <p className="text-red-500 text-xs mt-1">{errorModal.nom}</p>}

                                      <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                                            <input
                                              type='text'
                                              className='w-[140px] px-2 h-10 outline-none'
                                              placeholder='Prénom'
                                              name="prenom"
                                              value={formModal.prenom}
                                              onChange={handelFormModalChange}
                                            />
                                      </div>
                                      {errorModal.prenom && <p className="text-red-500 text-xs mt-1">{errorModal.prenom}</p>}
                                    </>
                                  )}
                                </div>
                                {/* nom */}
                                {entre && (
                                    <>
                                      <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                                        <input
                                          type='text'
                                          className='w-[340px] px-2 h-10 outline-none'
                                          placeholder="Nom de l'entreprise"
                                          name="nom"
                                          value={formModal.nom}
                                          onChange={handelFormModalChange}
                                        />
                                    </div>
                                      {errorModal.nom && <p className="text-red-500 text-xs mt-1">{errorModal.nom}</p>}
                                    </>
                                    
                                  )
                                }
                              <div className="mb-3 border-2 mt-3 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                                  <input
                                    type="text"
                                    className="w-[300px] px-2 h-10 outline-none"
                                    placeholder="Adresse e-mail"
                                    name="mail"
                                    value={formModal.mail}
                                    onChange={handelFormModalChange}
                                  />
                              </div>
                               {errorModal.mail && <p className="text-red-500 text-xs mt-1">{errorModal.mail}</p>}
                              <div className="mb-3 border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                                  <input
                                    type="text"
                                    className="w-[300px] px-2 h-10 outline-none"
                                    placeholder="Téléphone"
                                    name="tel"
                                    value={formModal.tel}
                                    onChange={handelFormModalChange}
                                  />
                              </div>
                              {errorModal.tel && <p className="text-red-500 text-xs mt-1">{errorModal.tel}</p>}
                              <button type="submit" className='bg-[#D19E06] text-white w-[340px] h-10 rounded-md'>
                                  Valider 
                              </button>
                          </form>
                    <div className="flex justify-end mt-4 space-x-4">
                      <button
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={handleCloseModal}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
            )} 
        </div>
        </>
      )}
   </div>
  )
}

export default Home