import React from 'react';
import Create from './Create';

const Index = () => {
    const paniers = JSON.parse(localStorage.getItem('paiementDetails'))
    const idCurrency = JSON.parse(localStorage.getItem('idCurrency'));
    const countrie = JSON.parse(localStorage.getItem('country'));
    let country;
    if(paniers.filtered.Country ){
        country= paniers.filtered.Country;
    }else if(!paniers.filtered.Country && countrie)
    {
      country = countrie;
    }else{
        country = 'BJ'
    }
    const item = {
        'id': 2,
        'name': 'Bag',
        'price': paniers['totaux'],
    }
    const  options = {
        id_currency: idCurrency,
        success_url: window.location.origin,
        cancel_url: window.location.href,
        customer: {
            id: paniers.filtered.Customers_Numbers,
            first_name: paniers.filtered.LastName,
            last_name: paniers.filtered.Names,
            email: paniers.filtered['E-mails'],
            phone: paniers.filtered.Phones,
            code_country: country 
        },
        seller_number: 1
    }
   // localStorage.removeItem('paiementDetails')
   

    return (
        <Create item={item} options={options} />
    );
};

export default Index;