import axios from "axios"


export const  paiement = async (payement)=>{
   try{
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storepaiement`,payement);
      return response.data;
   }catch(error){
    console.log("Erreur lors de l'envoi des données au serveur ",error)
    throw Error;
   }
}

// export const  storePaiement = async (payement)=>{
//    try{
//       const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/storePaiementWithProvider`,payement);
//       return response.data;
//    }catch(error){
//     console.log("Erreur lors de l'envoi des données au serveur ",error)
//     throw Error;
//    }
// }