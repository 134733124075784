import React, { createContext, useState} from 'react';

// Créer le contexte
export const CartContext = createContext();

// Provider pour le contexte
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProductIndex = prevCart.findIndex((item) => item.id === product.id);
      if (existingProductIndex >= 0) {
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quantity += 1;
        return updatedCart;
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const updateQuantity = (productId, quantity) => {
    setCart((prevCart) => {
      return prevCart.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      );
    });
  };

  const removeProduct = (productId) => {
    setCart((prevCart) => {
        return prevCart.filter((item) => item.id !== productId);
    });
};

const calculateTotal = () => {
    return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
};

const clearCart = ()=>{
  return setCart([]);
}

const decreaseQuantity = (productId) => {
    setCart((prevCart) => {
      return prevCart.map((item) =>
        item.id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    });
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, updateQuantity, decreaseQuantity,removeProduct,calculateTotal,clearCart}}>
      {children}
    </CartContext.Provider>
  );
};
