import axios from "axios";
export const update = async (id, userData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/update/${id}`, userData);
    return response.data; // Retournez la réponse pour que l'appelant puisse l'utiliser
  } catch (error) {
    console.error("Erreur lors du chargement :", error);
    throw error; // Relancez l'erreur pour que l'appelant puisse la gérer
  }
};

