import React from 'react';
import axios from 'axios';
import { BACKEND_URL, PAYTECH_PUBLIC_KEY, PAYTECH_SECRET_KEY, USE_LIVE_MODE } from "../config";

class PayTechApi
{
    #URL = "https://paytech.sn/api/payment/request-payment";

    #customeField = {}
    #query = {}
    #notificationUrl = {
        success: null,
        cancel: null,
        ipn: null
    }
    #mode = "test"
    #currency = "XOF"

    constructor(public_key, secret_key)
    {
        this.public_key = public_key;
        this.secret_key = secret_key;
    }

    sendRequest()
    {
        let params = {
            currency: this.#currency,
            env: this.#mode,
            ipn_url: this.#notificationUrl.ipn,
            success_url: this.#notificationUrl.success,
            cancel_url: this.#notificationUrl.cancel,
            custom_field:JSON.stringify(this.#customeField)
        };
        params= Object.assign({}, params, this.#query)
        console.log(params);
        
        fetch(this.#URL, {
            method:'POST',
            body:JSON.stringify(params),
            headers: {
                Accept: "application/json",
                'Content-Type': "application/json",
                API_KEY: this.public_key,
                API_SECRET: this.secret_key,
            }
        })
        .then(function (response) {
            return response.json()
        })
        .then(function (jsonResponse) {
            if (jsonResponse['success'] === 1 &&  jsonResponse['token']) {
                localStorage.setItem('paymentId', jsonResponse['token']);
                window.location.href = jsonResponse['redirect_url'];
            }
        })
    }

    setQuery(item_name, item_price, ref_command, command_name)
    {
        this.#query = {
            item_name: item_name,
            item_price: item_price,
            ref_command: ref_command ? ref_command : "HBZZYZVUZZZV",
            command_name: command_name ? command_name : "Paiement avec PayTech"
        };

        return this;
    }

    setLiveMode(status)
    {
        if(status)
            this.#mode = "prod";
        else
            this.#mode = "test";

        return this;
    }

    setCurrency(currency)
    {
        this.#currency = currency.toUpperCase();
        return this;
    }

    setCancelUrl(url)
    {
        this.#notificationUrl.cancel = url;
        return this;
    }

    setSuccessUrl(url)
    {
        this.#notificationUrl.success = url;
        return this;
    }

    setIpnUrl(url)
    {
        this.#notificationUrl.ipn = url;
        return this;
    }

    setCustomeField(customeField)
    {
        if(typeof customeField == Object)
        {
            this.#customeField = customeField;
        }

        return this;
    }
}

const PayTech = ({item, options}) => {
    const handle = () => {
        let success_url = options['success_url'];
        let cancel_url = options['cancel_url'];
        let description = options['description'] ?? `Paiement pour ${item['name']} via PayTech`;
        let amountXof = Math.round(item['price']);
        const id_currency = options['id_currency'];
        
        if(id_currency !== 3)
        {
            axios.get(`${BACKEND_URL}payment/conversion/${id_currency}`)
            .then(response => amountXof = Math.round(item['price'] * response.data.currency.value_to_xof))
        }
        
        setTimeout(() => {
            let tab=success_url.split('?');
            success_url=tab[0];
            let query=tab[1] ?? '';
            if(query.length >0) query +='&';
            query += 'provider=4&state=success&id=' + item['id'] + '&id_currency='+ id_currency +'&paymentId=';
        
            const ref_command = (new Date()).getTime();
            
            const provider= new PayTechApi(PAYTECH_PUBLIC_KEY, PAYTECH_SECRET_KEY);
            provider.setQuery(item['name'], amountXof, 'CMD/' + item['id'] +'/'+ ref_command, description)
                    .setCustomeField({
                        'item_id': item['id'],
                        'time_command': ref_command,
                    })
                    .setLiveMode(USE_LIVE_MODE ? true : false)
                    .setIpnUrl(window.location.origin +'/ipn.php')
                    .setSuccessUrl(success_url +'?'+ query)
                    .setCancelUrl(cancel_url +'?state=cancel&id=' + item['id'])
                    .sendRequest();
        }, 7000);
    }

    return (
        <button onClick={() => handle()} className='text-white bg-[#D19E06] hover:bg-black px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block'>
            Mobile Money
        </button>
    );
};

export default PayTech;