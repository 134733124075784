import restoImage from "../../ASSETS/Image/top-view-cooked-chicken-spiced-up-with-potatoes-dark-surface.jpg";
import { FaUserLarge } from "react-icons/fa6";
import {Link, useNavigate} from 'react-router-dom'
import { IoReturnUpBack } from "react-icons/io5";
import Loading from '../../COMPONENTS/Loading';
import { useState, useEffect } from 'react';
import { accountService } from "../../services/Account.service";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logo } from "../../API/Logo/logo";
import { forgot } from "../../API/login/forgot";

function ForgotPassword() {

  const [mail,setMail] = useState('');
  const [loading, setLoading] = useState(true);
      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);
      const navigate = useNavigate();
      useEffect(() => {
        const token = accountService.getToken();
        if (token) {
          navigate('/');
        }
      }, [navigate]);
     
  
      const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])
     
      const onSubmit = async (e) => {
        e.preventDefault();
        let email = JSON.stringify(mail);
        localStorage.setItem('email',email);
        if(mail === ''){
          return toast.error('Veuillez renseigner votre adresse mail')
        }
        const response = await forgot({
          mail:mail
        });
        if(response.status === 200){
          toast.success(response.message)
        }
        if(response.status === 422){
          toast.error(response.error)
        }
      };
      
  return (
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
      <div
      className="bg-center bg-cover bg-no-repeat h-screen flex justify-end"
      style={{ backgroundImage: `url(${restoImage})` }}
    > 
      <form onSubmit={onSubmit} className="bg-white w-[600px] flex flex-col space-y-7 text-lg items-center justify-center ">
        <div className="text-4xl font-bold"><img className="w-40 h-40 rounded-full object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/></div>
        <div className="text-2xl font-bold">ON-SITE</div>
        <div className="font-semibold">Reinitialisation du mot de passe</div>
        <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px]  flex flex-row items-center px-3">
            <FaUserLarge className="text-[#a5a5a5]" />
            <input
                type="text"
                value={mail}
                name="mail"
                onChange={(e)=>setMail(e.target.value)}
                className=" h-12 px-3 w-[370px] bg-transparent outline-none text-base"
                placeholder="Addresse e-mail"
            />
        </div>
        <ToastContainer />
        <button className="bg-[#D19E06] transition ease-in-out delay-150 
            hover:-translate-y-1 hover:scale-110 duration-700 
            hover:bg-white hover:text-[#D19E06] s:w-[400px] 
            xs:w-[240px] sm:w-[300px] text-white h-12">
              Reinitialisation
        </button>
        <Link to='/' className="flex hover:text-[#D19E06] items-center space-x-2 text-sm">
          <IoReturnUpBack className="text-xl" />
          <div>Retour a la page d'accueil</div>
        </Link>
      </form>
      </div>
      </>
      )}
    </div>
   
  );
}

export default ForgotPassword;
