import axios from "axios"

export const factures = async(page)=>{
    try{
        const response  = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/facture`,{
            params:{
                page:page,
                limite: '4'
            }
        })
        return response.data;
    }catch(error){
        console.log("Erreur lors de l'appel a l'API ",error)
    } 
}