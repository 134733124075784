import React from 'react'
import { MutatingDots } from 'react-loader-spinner'

function Loading() {
  return (
      <div className=' w-screen h-screen flex justify-center items-center' style={{ backgroundColor:"black"}}>
        <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#D19E06"
        secondaryColor="#fff"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        />
    </div>
  )
}

export default Loading