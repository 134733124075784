import table from "../../ASSETS/Icone/table.png"
import Loading from '../../COMPONENTS/Loading';
import React, { useState, useEffect, useRef } from 'react';
import  Header  from "../../COMPONENTS/Header";
import { toast, ToastContainer } from 'react-toastify';
import { logo } from "../../API/Logo/logo";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { all } from "../../API/Reservation/all";
import { factures } from "../../API/factures/factures";
import { FaCalendarAlt, FaFileInvoiceDollar } from 'react-icons/fa';
import axios from "axios";


function Document() {
  const [loading, setLoading] = useState(true);

      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);
      
  const [search,setSearch] = useState('');
  const [startDate,setStartDate] = useState('');
  const [startTime,setStartTime] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [facture,setFacture] = useState(true);
  const [reservation,setReservation] = useState(false)
  const [reservations,setReservations] = useState([]);
  const [openIndex, setOpenIndex] = useState(null); // Stocker l'index du menu ouvert
  const [openIndexe, setOpenIndexe] = useState(null); // Stocker l'index du menu ouvert
  const [facturations,setFacturations] = useState([]);
  const [bgColorf, setBgColorf] = useState('bg-[rgb(32,147,19)]');
  const [bgColorr, setBgColorr] = useState('bg-[#C2C2C2]');
  const [currentPage,setCurrentPage] = useState(1);
  const [totalPage,setTotalPage] = useState(1);
  const [totalPageReservation,setTotalPageReservation] = useState(1);
  const [currentPageReservation,setCurrentPageReservation] = useState(1);
  const [filteredReservations,setFilteredReservations] = useState([]);


 const handleReservationChange = (event)=>{
    const seachValue = event.target.value.toLowerCase();
    setSearch(seachValue)
 }

  const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])
  const OnFactureClick = ()=>{
    setFacture(true)
    setReservation(false)
    setBgColorf('bg-[rgb(32,147,19)]')
    setBgColorr('bg-[#C2C2C2]')
  }

  const OnReservationClick = ()=>{
    setFacture(false)
    setReservation(true)
    setBgColorf('bg-[#C2C2C2]')
    setBgColorr('bg-[rgb(32,147,19)]')
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/getReservationDocument`, {
      params: {
        page: currentPageReservation,
        limit: '4'
      }
    })
    .then((response) => {
      if (response.data.documents) {
        const newReservations = response.data.documents.data;
        setReservations(newReservations);
        setCurrentPageReservation(response.data.documents.current_page);
        setTotalPageReservation(response.data.documents.last_page);
      } else {
        console.log("Erreur");
      }
    })
    .catch((error) => {
      console.log("Erreur lors de la récupération", error);
    });
  }, [currentPageReservation]); // On surveille currentPageReservation pour déclencher l'effet
  
  useEffect(() => {
    if (search || startDate || startTime) {
      // Transform the reservations data, separating date and time
      const transformedReservations = reservations.map((reserve) => {
        //console.log(reserve.starting_date)
        const [date, time] = reserve.starting_date.split(' : ');
        return { ...reserve, starting_date: date, starting_time: time };
      });
  
      // Apply filters based on search and startDate
      const filtered = transformedReservations.filter((reserve) => {
        const formatDate = (dateString)=>{
          const date = new Date(dateString)
          return date.toLocaleDateString('fr-FR',{
              day:'2-digit',
              month:'2-digit',
              year:'numeric'
          });
        }
        const matchDate = startDate === '' || (reserve.starting_date && reserve.starting_date.includes(formatDate(startDate)));
        const matchTime = startTime === '' || (reserve.starting_time && reserve.starting_time.includes(startTime));
        const matchSearch = search === '' || 
          (reserve.customer_name && reserve.customer_name.toLowerCase().includes(search.toLowerCase())) ||
          (reserve.customer_last_name && reserve.customer_last_name.toLowerCase().includes(search.toLowerCase()));
          (reserve.group_reservation && reserve.group_reservation.toLowerCase().includes(search.toLowerCase()));
        return matchDate && matchSearch && matchTime;
      });
  
      // Update the reservations state with the filtered data
     setFilteredReservations(filtered);
    }
  }, [reservations,search, startDate,startTime]); // On surveille reservations, search et startDate pour déclencher l'effet
  

  useEffect(()=>{
     factures(currentPage).then((response)=>{
           setFacturations(response.factures.data)
           setCurrentPage(response.factures.current_page)
           setTotalPage(response.factures.last_page)   
     }).catch((error)=>{
      console.log("Erreur lors de la récuperation ",error)
     })
  },[currentPage])

  const toggleMenu = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Fermer le menu si c'est déjà ouvert
    } else {
      setOpenIndex(index); // Ouvrir le menu pour cet index
    }
  };

  const toggleMenue = (indexe) => {
    if (openIndexe === indexe) {
      setOpenIndexe(null); // Fermer le menu si c'est déjà ouvert
    } else {
      setOpenIndexe(indexe); // Ouvrir le menu pour cet index
    }
  };
  const handleDownloadFacture = (factureNoInvoice) => {
    axios({
        url: `${process.env.REACT_APP_BASE_URL}/api/facture/download/${factureNoInvoice}`, // Assurez-vous que l'URL est correcte
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'facture.pdf'); // Correction ici
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Nettoyage du DOM
        window.URL.revokeObjectURL(url); // Libération de l'URL blob
    }).catch((error) => {
        console.log('Erreur lors du téléchargement ', error);
    });
  };

  const handeDowloadReservation  = (idreservation)=>{
      axios({
        url: `${process.env.REACT_APP_BASE_URL}/api/reservation/dowload/${idreservation}`,
        method:'GET',
        responseType:'blob',
        headers:{
          'Content-Type':'application/json'
        },
      }).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download','reservation.pdf');
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
      }).catch((error)=>{
        console.log("Erreur lors du téléchargement de la reservation ",error)
      })
  };

 const handleMailFacturSend = (factureNoInvoice)=>{
   axios.get(`${process.env.REACT_APP_BASE_URL}/api/getFactureMail/mail/${factureNoInvoice}`)
        .then((response)=>{
           if(response.data.status === 200){
            toast.success(response.data.message)
           }
        }).catch((error)=>{
          console.log("Erreur lors de l'envoie ",error)
        })
 };

 const handleMailReservationSend = (idreservation)=>{
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/getReservationMail/mail/${idreservation}`)
        .then((response)=>{
           if(response.data.status ===200){
            toast.success(response.data.message)
           }
        }).catch((error)=>{
          console.log("Erreur lors de l'envoie du mail ",error)
        })
 };




  return (
    
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
         <ToastContainer />
          <div className='flex flex-row bg-[#F7F7F7] h-screen'>
            {/* Section gauche */}
            <div className='w-2/3 barre_scroll'>
              {/* Navbar */}
              <div className='flex s:justify-between s:flex-row xs:flex-col s:h-20 xs:h-32 s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md s:px-6 xs:px-2 z-10 fixed w-2/3'>
                <div className='text-3xl'>
                   <img className="w-40 h-10  object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
                </div>
                <strong><h1 className="text-2xl font-bold">ON-SITE</h1></strong> 
                <div>
                  <input type='search' onChange={handleReservationChange} className='bg-[#EEEEEE] h-10 px-3  outline-none text-base' placeholder='Entrer un mot clé' />
                </div>
              </div>
              <div className='p-6 s:text-2xl xs:text-lg text-[#4E4E4E] mt-20'>Listes des factures et réservations</div>

              <div className='flex s:flex-row xs:mt-7 xs:flex-col s:justify-center s:items-center s:space-x-6 xs:space-y-6 s:space-y-0 pl-6'>
                <input type='date' className='h-10 w-[220px] px-3 bg-white rounded-md' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <input type='time' className='h-10 w-[100px] px-3 bg-white rounded-md' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
              </div>

              <div className='p-6 mt-7'>
                <div className='flex s:flex-row s:space-x-6 xs:flex-col xs:space-y-6 s:space-y-0'>
                  {/* place reservé */}
                  <div onClick={OnFactureClick} 
                  className={`${bgColorf} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}>
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                       {/* <img src={table} alt='table' />  */}
                       <FaFileInvoiceDollar size={24} />
                    </div>
                    <div  className='text-lg'>Factures</div>
                  </div>
                  {/* place disponible */}
                  <div onClick={OnReservationClick} 
                    className={`${bgColorr} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}>
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                      {/* <img src={table} alt='table' /> */}
                      <FaCalendarAlt size={24} />
                    </div>
                    <div  className='s:text-lg xs:text-base text-dark'>Confirmations de RSV</div>
                  </div>
                </div>
                <div className='grid s:grid-cols-1 xs:grid-cols-1 my-20 gap-y-6 '>
                  {
                    facture && (
                      <table className="min-w-full border-collapse border table hover:table border-gray-300">
                          <thead>
                              <tr className="bg-gray-100">
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Action</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">No. Vente</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date de CMD</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Nbre de designation</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Client</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Monant total payer</th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                              facturations.map((facturation,indexe)=>(
                                <tr key={indexe}>
                                    <th scope="row" className="px-4 py-2 border border-gray-300">
                                        <div className="relative inline-block text-left">
                                            <div>
                                                <button
                                                type="button"
                                                className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                id="menu-button"
                                                aria-expanded={openIndex === indexe}
                                                aria-haspopup="true"
                                                onClick={()=>toggleMenue(indexe)} // Trigger menu toggle on click
                                                >
                                                <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                                </button>
                                            </div>
                                            {openIndexe === indexe && (
                                                <div
                                                    className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    role="menu"
                                                    aria-orientation="vertical"
                                                    aria-labelledby="menu-button"
                                                    tabIndex="-1"
                                                    >
                                                    <div className="py-1" role="none">
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-0"
                                                            >
                                                            Détail
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-1"
                                                            onClick={()=>handleDownloadFacture(facturation.invoice_number)}
                                                            >
                                                            Télécharger Facture
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-2"
                                                            onClick={()=>handleMailFacturSend(facturation.invoice_number)}
                                                            >
                                                            Envoyer Facture au client
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoice_number}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.Dates}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoce_quanity}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.Customers} </td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoce_total_sales} F CFA</td>
                              </tr>
                              ))
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="6">
                                <div className="pagination float-end">
                                  <button 
                                    disabled={currentPage === 1} 
                                    className="mr-4 text-2xl"
                                    onClick={() => setCurrentPage(currentPage - 1)}>
                                   <strong>Précédent</strong>
                                  </button>
                                  
                                  <span> {currentPage} sur {totalPage}</span>
                                  
                                  <button 
                                    disabled={currentPage === totalPage}
                                    className="ml-4 text-2xl" 
                                    onClick={() => setCurrentPage(currentPage + 1)}>
                                      <strong>Suivant</strong> 
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                     </table>
                     
                    )
                    
                  }
                  {
                    filteredReservations.length ===0 && reservation && (
                      <table className="min-w-full border-collapse border table hover:table border-gray-300">
                       <thead>
                          <tr className="bg-gray-100">
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Action</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">NO.Reservation</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Nombre de designation</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Nom du client</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date de la reservation</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date et heure d'arrivée</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date et heure de départ  </th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                          reservations.map((reserve,index)=>(
                            <tr key={index}>
                            <th scope="row" className="px-4 py-2 border border-gray-300">
                                <div className="relative inline-block text-left">
                                    <div>
                                        <button
                                        type="button"
                                        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        id="menu-button"
                                        aria-expanded={openIndex === index}
                                        aria-haspopup="true"
                                        onClick={()=>toggleMenu(index)} // Trigger menu toggle on click
                                        >
                                        <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                        </button>
                                    </div>
                                    {openIndex === index && (
                                        <div
                                            className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="menu-button"
                                            tabIndex="-1"
                                            >
                                            <div className="py-1" role="none">
                                                <Link
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="menu-item-0"
                                                    >
                                                    Détail
                                                </Link>
                                                <Link
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="menu-item-1"
                                                    onClick={()=>handeDowloadReservation(reserve.reservation_id)}
                                                    >
                                                    Télécharger
                                                </Link>
                                                <Link
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="menu-item-2"
                                                    onClick={()=>handleMailReservationSend(reserve.reservation_id)}
                                                    >
                                                    Envoyer Confirmation
                                                </Link>
                                                
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </th>
                            <td className="px-4 py-2 border border-gray-300">{reserve.group_reservation}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.quantities}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.customer_last_name} {reserve.customer_name}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.starting_date}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.starting_date}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.ending_date ? reserve.ending_date : 'En cours...'}</td>
                        </tr>
                          ))
                        }
                          
                      </tbody>
                      <tfoot>
                            <tr>
                              <td colSpan="7">
                                <div className="pagination float-end">
                                  <button 
                                    disabled={currentPageReservation === 1} 
                                    className="mr-4 text-2xl"
                                    onClick={() => setCurrentPageReservation(currentPageReservation - 1)}>
                                   <strong>Précédent</strong>
                                  </button>
                                  
                                  <span> {currentPageReservation} sur {totalPageReservation}</span>
                                  
                                  <button 
                                    disabled={currentPageReservation === totalPageReservation}
                                    className="ml-4 text-2xl" 
                                    onClick={() =>setCurrentPageReservation(currentPageReservation + 1)}>
                                      <strong>Suivant</strong> 
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                     </table>
                    )
                  }

                  {
                    filteredReservations.length > 0 && reservation && (
                      <table className="min-w-full border-collapse border table hover:table border-gray-300">
                       <thead>
                          <tr className="bg-gray-100">
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Action</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">NO.Reservation</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Nombre de designation</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Nom du client</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date de la reservation</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date et heure d'arrivée</th>
                          <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">Date et heure de départ  </th>
                          </tr>
                      </thead>
                      <tbody>
                        {
                          filteredReservations.map((reserve,index)=>(
                            <tr key={index}>
                            <th scope="row" className="px-4 py-2 border border-gray-300">
                                <div className="relative inline-block text-left">
                                    <div>
                                        <button
                                        type="button"
                                        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        id="menu-button"
                                        aria-expanded={openIndex === index}
                                        aria-haspopup="true"
                                        onClick={()=>toggleMenu(index)} // Trigger menu toggle on click
                                        >
                                        <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                        </button>
                                    </div>
                                    {openIndex === index && (
                                        <div
                                            className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="menu-button"
                                            tabIndex="-1"
                                            >
                                            <div className="py-1" role="none">
                                                <Link
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="menu-item-0"
                                                    >
                                                    Détail
                                                </Link>
                                                <Link
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="menu-item-1"
                                                    onClick={()=>handeDowloadReservation(reserve.reservation_id)}
                                                    >
                                                    Télécharger
                                                </Link>
                                                <Link
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700"
                                                    role="menuitem"
                                                    tabIndex="-1"
                                                    id="menu-item-2"
                                                    onClick={()=>handleMailReservationSend(reserve.reservation_id)}
                                                    >
                                                    Envoyer Confirmation
                                                </Link>
                                                
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </th>
                            <td className="px-4 py-2 border border-gray-300">{reserve.group_reservation}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.quantities}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.customer_last_name} {reserve.customer_name}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.starting_date}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.starting_date}</td>
                            <td className="px-4 py-2 border border-gray-300">{reserve.ending_date ? reserve.ending_date : 'En cours...'}</td>
                        </tr>
                          ))
                        }
                          
                      </tbody>
                      <tfoot>
                            <tr>
                              <td colSpan="7">
                                <div className="pagination float-end">
                                  <button 
                                    disabled={currentPageReservation === 1} 
                                    className="mr-4 text-2xl"
                                    onClick={() => setCurrentPageReservation(currentPageReservation - 1)}>
                                   <strong>Précédent</strong>
                                  </button>
                                  
                                  <span> {currentPageReservation} sur {totalPageReservation}</span>
                                  
                                  <button 
                                    disabled={currentPageReservation === totalPageReservation}
                                    className="ml-4 text-2xl" 
                                    onClick={() =>setCurrentPageReservation(currentPageReservation + 1)}>
                                      <strong>Suivant</strong> 
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                     </table>
                    )
                  }
                </div>
              </div>
            </div>
            <div className='w-1/3 bg-white barre_scroll'>
              <Header />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Document