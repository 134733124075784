export const PAYTECH_PUBLIC_KEY = '725a9ea4f8a4acdf9af81f4fe20de6a2ba6962b1a63e124bdb6c546c845416ba'
export const PAYTECH_SECRET_KEY = 'dd3527666a37a6aab0938723b98b8d79e77de3a253df2091050ce322ed9d615f'

export const STRIPE_PUBLIC_LIVE_KEY = 'pk_live_51Osr9aLgXFYQApwhSACSMXPn0QmMIaxofp0Di2g5r68NaUBPqjH7ZKEqmihPzj9TrrQLDYcHMiORLpJfBMjLrP5800tNZAPa3w'
export const STRIPE_SECRET_LIVE_KEY = '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'
export const STRIPE_PUBLIC_TEST_KEY = 'pk_test_51J0lmaASzA10T8ncxsG5fwmEiStyoui1kyLHHFxh3LOAlPiCdNYmPqggqwuHjB8y6JvIjr7pfQrJnLL2avgzVlTL00AZF73nCa'
export const STRIPE_SECRET_TEST_KEY = 'sk_test_51J0lmaASzA10T8ncrxjG1wGlOojEOYCTGFrWA2BpXb6R4mfmqwEcHjI7QUt6iWWVN3LkFKSJ3ACP9IaFXyh69bj200xoP1oAYV'

export const FEDAPAY_PUBLIC_KEY = 'pk_sandbox_GVYrmawmN6UDU4Y0YVCLTeQi'

//export const BACKEND_URL = 'http://127.0.0.1:8000/api/'
export const BACKEND_URL = 'https://onsite.az-companies.com/sources/api/'
export const USE_LIVE_MODE = false