import React, { useState, useTransition } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { loadStripe } from '@stripe/stripe-js';
import Dialog from '@mui/material/Dialog'; // Utilisation de Dialog de @mui/material
import {useEffect } from 'react';
import axios from 'axios';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// function PaymentPopup({open}) {
//   const [opens, setOpen] = useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handlePayCancel = () => {
//     //console.log('Paiement annulé');
//     handleClose();
// };
// // Lancer le paiement FedaPay
// const handlePayment = () => {
//   const FedaPay = window['FedaPay'];
//   FedaPay.init(checkoutButtonOptions).open();
// };
//   const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
//   const checkoutButtonOptions = {
//       public_key: PUBLIC_KEY,
//       transaction: {
//         amount: 300,
//         description: 'Don pour Association',
//         callback_url: 'Dashboard/Publications/Hebergement/Ajouter_des_hebergements',
//         custom_metadata: {
//               user_id: 2,
//               //additional_info: 'Some additional info'
//           }
//       },
//       currency: {
//         iso: 'XOF'
//       },
//       customer: {
//         lastname: 'fabrice',
//         firstname: 'eric',
//         email: 'test@gmail.com'
//       },
//       button: {
//         class: 'btn btn-primary',
//         text:`${('Payer')} ${255} FCFA`
//       },
//       onComplete(resp) {
//         const FedaPay = window['FedaPay'];
//         if (resp.reason === FedaPay.DIALOG_DISMISSED) {
//           //window.location.reload();
//          // toast.success('Vous avez fermé la boite de dialogue');
//         } else {
//           //setIsDialogOpen(false);
//           //onSuccess();
  
//           // Extraire les données de la transaction
//           const transactionData = {
//             transaction_id: resp.transaction.id,
//             reference: resp.transaction.reference,
//             amount: resp.transaction.amount,
//             description: resp.transaction.description,
//             status: resp.transaction.status,
//             approved_at: resp.transaction.approved_at,
//             customer: {
//               firstname: resp.transaction.customer.firstname,
//               lastname: resp.transaction.customer.lastname,
//               email: resp.transaction.customer.email,
//             },
//             transaction_key: resp.transaction.transaction_key,
//             fees: resp.transaction.fees,
//             mode: resp.transaction.mode,
//             amount_debited: resp.transaction.amount_debited,
//             custom_metadata:resp.transaction.custom_metadata.user_id,
//           };
//           console.log('Transaction Data: ',transactionData);
//           console.log(checkoutButtonOptions);
//           // Envoyer les données de la transaction au backend
//           // fetch(${process.env.REACT_APP_BASE_URL}/api/fedapay/callback, {
//           //   method: 'POST',
//           //   headers: {
//           //     'Content-Type': 'application/json',
//           //     'Accept': 'application/json'
//           //   },
//           //   body: JSON.stringify(transactionData),
//           // })
//           // .then(response => {
//           //   if (!response.ok) {
//           //     return response.json().then(errorData => {
//           //       throw new Error(errorData.message || 'Something went wrong');
//           //     });
//           //   }
//           //   return response.json();
//           // })
//           // .then(data => {
//           //   console.log('Transaction saved:', data);
//           // })
//           // .catch((error) => {
//           //   console.error('Error:', error);
//           // });
//         }
  
//         console.log(resp.transaction);
        
//       },
//       onCancel: handlePayCancel
//   };

//   return (
//     <div>
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//         Ouvrir la boîte de dialogue
//       </Button>
//       <BootstrapDialog 
//         onClose={handleClose} 
//         aria-labelledby="customized-dialog-title" 
//         open={open}
//       >
//         <DialogTitle id="customized-dialog-title" className="flex items-center text-orange-600 space-x-3 p-2">
//           <p>Veuillez choisir le mode de paiement</p>
//           <IconButton 
//             aria-label="close" 
//             className="absolute right-2 top-2 text-gray-500" 
//             onClick={handleClose}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent dividers className="p-4">
//           <Typography gutterBottom>
//             {/* Contenu ici */}
//           </Typography>
//         </DialogContent>
//         <DialogActions className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 p-4">
//           <Button className="text-none bg-blue-900 text-white py-2 px-4 rounded">
//             {/* Texte ou fonction ici */}
//           </Button>
//           <Button onClick={handlePayment} className={`${checkoutButtonOptions.button.class} text-none bg-blue-900 text-white py-2 px-4 rounded`}>
//             {checkoutButtonOptions.button.text}
//           </Button>
//           <Button className="text-none bg-blue-900 text-white py-2 px-4 rounded">
//             Stripe : 1 F CFA
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </div>
//   );
// }
function PaymentPopup({ open,tel, user_Id, montant, email, onSuccess, userPrenom,userNom, onClose,cart,person,handlCashSubmit }) {
  const [isDialogOpen, setIsDialogOpen] = useState(open);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  //const {t} = useTranslation()

  useEffect(() => {
      setIsDialogOpen(open);
  }, [open]);
  //console.log(email)
  const handleOpenAZ = () => {
     
  };

  
  const createPaymentSession = async () => {
    try {
  
        // const tauxConversion = 555; // 1 USD = 555 XOF
        // const montantEnUSD = montant / tauxConversion; // Convertir le montant en USD
        // const montantEnCentimes = montantEnUSD * 100; // Convertir en centimes pour Stripe
        // const montantArrondi = Math.round(montantEnCentimes); // Arrondir à l'entier le plus proche
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/create`, {
        provider: 'stripe',
        item_id: 'item_id',
        item_name: 'item',
        amount: montant,
        currency: 'XOF',
        email: email,
        description: 'Publicité sur Mon Bon Sejour',
        phone: tel,
        last_name: userNom,
        first_name: userPrenom,
        success_url: `${window.location.origin}`,
        customer_number:1,
        cart:cart,
        person:person
      });

      if (response.data) {
        localStorage.setItem('sessionId', response.data.sessionId);
        return response.data.sessionId;
        
      } else {
        console.error("Erreur lors de la création de la session de paiement :", response.data);
      }
    } catch (error) {
      console.error("Erreur lors de la requête Card :", error);
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    if (onClose) onClose();  
};
 

  let PUBLIC_KEY='pk_sandbox_K033T7_Li724jKUuE431xNuL'
  const handlePayCancel = () => {
      //console.log('Paiement annulé');
      handleClose();
  };
  if (!process.env.REACT_APP_PUBLIC_KEY) {
    console.error("La clé publique n'est pas définie !");
  } else {
     PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
    // Continuez avec la configuration du paiement...
  }
  const mt = montant * 436
  const checkoutButtonOptions = {
      
      public_key: PUBLIC_KEY,
      transaction: {
        amount: mt,
        description: 'Don pour Association',
        callback_url: 'Dashboard/Publications/Hebergement/Ajouter_des_hebergements',
        custom_metadata: {
              user_id: user_Id,
              //additional_info: 'Some additional info'
          }
      },
      currency: {
        iso: 'XOF'
      },
      customer: {
        lastname: userNom,
        firstname: userPrenom,
        email: email
      },
      button: {
        class: 'btn btn-primary',
        text: `Payer ${mt} FCFA`
      },
      onComplete(resp) {
        const FedaPay = window['FedaPay'];
        if (resp.reason === FedaPay.DIALOG_DISMISSED) {
          //window.location.reload();
          //toast.success('Vous avez fermé la boite de dialogue');
        } else {
          setIsDialogOpen(false);
          onSuccess();
  
          // Extraire les données de la transaction
          const transactionData = {
            transaction_id: resp.transaction.id,
            reference: resp.transaction.reference,
            amount: resp.transaction.amount,
            description: resp.transaction.description,
            status: resp.transaction.status,
            approved_at: resp.transaction.approved_at,
            customer: {
              firstname: resp.transaction.customer.firstname,
              lastname: resp.transaction.customer.lastname,
              email: resp.transaction.customer.email,
            },
            transaction_key: resp.transaction.transaction_key,
            fees: resp.transaction.fees,
            mode: resp.transaction.mode,
            amount_debited: resp.transaction.amount_debited,
            custom_metadata:resp.transaction.custom_metadata.user_id,
          };
          console.log('Transaction Data: ',transactionData);
          console.log(checkoutButtonOptions);
          // Envoyer les données de la transaction au backend
          // fetch(${process.env.REACT_APP_BASE_URL}/api/fedapay/callback, {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     'Accept': 'application/json'
          //   },
          //   body: JSON.stringify(transactionData),
          // })
          // .then(response => {
          //   if (!response.ok) {
          //     return response.json().then(errorData => {
          //       throw new Error(errorData.message || 'Something went wrong');
          //     });
          //   }
          //   return response.json();
          // })
          // .then(data => {
          //   console.log('Transaction saved:', data);
          // })
          // .catch((error) => {
          //   console.error('Error:', error);
          // });
        }
  
        console.log(resp.transaction);
        
      },
      onCancel: handlePayCancel
  };
  
  // Lancer le paiement FedaPay
  const handlePayment = () => {
    const FedaPay = window['FedaPay'];
    if (FedaPay && FedaPay.init) {
      FedaPay.init(checkoutButtonOptions).open();
    } else {
      console.error("FedaPay n'est pas correctement chargé.");
    }
  };

  const PayementCash = async() =>{
    handlCashSubmit();
    setIsDialogOpen(false);
    console.log(isDialogOpen)
    

  }
  const handleStripePayment = async () => {
    const sessionId = await createPaymentSession();
    console.log(sessionId);
    if (sessionId) {
      
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

     
    }
  };
    
  return (
    
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isDialogOpen}>
      <DialogTitle id="customized-dialog-title" className="flex items-center text-orange-600 space-x-3 p-2">
        <p>{('Veuillez choisir le mode de paiement')}</p>
        <IconButton aria-label="close" onClick={handleClose} className="absolute left-36 top-1 text-gray-500">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className="p-4">
        <Typography gutterBottom>
          {('Veuillez choisir un moyen de paiement pour finaliser votre commande')}
        </Typography>
      </DialogContent>
      <DialogActions className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 p-4">
        {/* <Button onClick={handleOpenAZ} className="text-none bg-blue-900 text-white py-2 px-4 rounded">
          Az-Pay : {montant} F CFA
        </Button> */}
        <Button onClick={handlePayment} className={`${checkoutButtonOptions.button.class} text-none bg-blue-900 text-white py-2 px-4 rounded mr-5`}>
          {checkoutButtonOptions.button.text}
        </Button>
        <Button onClick={handleStripePayment} className="text-none bg-blue-900 text-white py-2 px-4 rounded">
         {('Stripe')} : {montant} $ CAD
        </Button>
        <Button onClick={PayementCash} className="text-none bg-blue-900 text-white py-2 px-4 rounded">
         {('Paiement cash')} : {montant}$ CAD
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default PaymentPopup;
