import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { BACKEND_URL } from '../config';

const AZPAY = ({item, options}) => {
    const [passwordType, setPasswordType] = useState('password')
    const [password, setPassword] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)
    const [availableAmount, setAvailableAmount] = useState(false)
    const [disabled, setdDisabled] = useState(false)
    const [amountToPay, setAmountToPay] = useState(0)
    const [amountText, setAmountText] = useState('')
    const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState('')
    const btnClas = "px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block "
    const flag = useRef(false);
    
    const togglePassword = () => {
        if(passwordType === 'password')
            setPasswordType('text')
        else
            setPasswordType('password')
    }

    const confirm = () => {
        setdDisabled(true)
        if(password)
        {
            setErrorPasswordConfirmation("")
            axios.post(`${BACKEND_URL}payment/azpay/confirm`, {
                lang: 'fr',
                password: password,
                amount: amountToPay,
                customer_number: options['customer']['id'],
                seller_number: options['seller_number']
            })
            .then(response => {
                if(response.data.type === 'success')
                {
                    let tab = options.success_url.split('?');
                    let success_url= tab[0];
                    let query= tab[1] ?? '';
                    if(query.length >0) query +='&';
                    
                    query += 'provider=1&paymentId=' + response.data.paymentId +'&id_currency='+ options['id_currency'];
                    window.location = success_url +'?' + query;
                }else
                {
                    setErrorPasswordConfirmation(response.data.message)
                }
            })
        }
        else
        {
            setErrorPasswordConfirmation("Veuillez entrer votre mot de passe")
        }
    }

    useEffect(() => {
        if(flag.current === false)
        {
            axios.post(`${BACKEND_URL}payment/azpay/wallet`, {
                id_currency: options['id_currency'],
                amount: item['price'],
                customer_number: options['customer']['id']
            })
            .then(response => {
                if(response.data.success)
                {
                    setAvailableAmount(true);
                    setAmountToPay(response.data.amount_usd)
                    setAmountText(response.data.amount_to_pay)
                }
                else{
                    setAvailableAmount(false);
                }
                setIsLoaded(true);
            })
        }
        return () => flag.current = true;
    }, []);


    return (
        isLoaded &&
        <>
            <h5 className='mb-4 font-bold bg-gray-400 px-3 py-2 text-center'>Paiement avec AZ PAY</h5>
            {
                availableAmount 
                ?
                    <form>
                        <div className="form-outline mb-4">
                            <label className="text-gray-700 md:text-xl" htmlFor="azpayTransaction_password_confirmation">
                                Entrez votre mot de passe pour confirmer le paiement de 
                                <span className="font-bold ml-2">{amountText}</span> 
                                <span className="text-red-500">*</span>
                            </label>
                            <div className="flex items-center mt-3">
                                <input type={passwordType} id="azpayTransaction_password_confirmation" className='border rounded-md h-12 px-3 w-full bg-transparent outline-none text-base' onChange={e => {
                                    setPassword(e.target.value);
                                    setdDisabled(false);
                                }} />
                                <div className="-ml-7 cursor-pointer" onClick={() => togglePassword()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="text-red-500 mb-4 italic">{ errorPasswordConfirmation }</p>
                    
                        <button disabled={disabled} onClick={() => confirm()} className={disabled ? btnClas + 'bg-gray-200 text-gray-500' : btnClas + 'text-white bg-[#D19E06] hover:bg-black'} type="button" id="azpayTransactionConfirm">
                            Confirmer
                        </button>
                    </form>
                :
                    <div className='text-red-500 flex flex-col items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 md:w-16 md:h-16">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                        </svg>
                        <p className='mt-4 italic text-lg'>Oops! votre solde est insufissant.</p>
                    </div>
            }
        </>
    );
};

export default AZPAY;