import axios from "axios";

export const update = async(id,status)=>{
    try{
        const response= await axios.post(`${process.env.REACT_APP_BASE_URL}/api/update-reservation/${id}`,status);
        return response.data;
    }
    catch(e){
        console.log("Erreur lors de l'appel au server ",e)
        throw e;
    }
}