import React, { useState, useEffect, useRef } from 'react';
//installation package using npm install --save @stripe/react-stripe-js @stripe/stripe-js
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import "./Stripe.css";
import { STRIPE_PUBLIC_LIVE_KEY, STRIPE_SECRET_LIVE_KEY, STRIPE_PUBLIC_TEST_KEY, STRIPE_SECRET_TEST_KEY, BACKEND_URL, USE_LIVE_MODE } from '../../config';
import axios from 'axios';

const stripePromise = loadStripe(USE_LIVE_MODE ? STRIPE_PUBLIC_LIVE_KEY : STRIPE_PUBLIC_TEST_KEY);

const Stripe = ({item, options}) => {
    const id_currency = options['id_currency'];
    const [successUrl, setSuccessUrl] = useState('');
    const [clientSecret, setClientSecret] = useState("");
    const [amountToPay, setAmountToPay] = useState("");
    const flag = useRef(false);

    useEffect(() => {

        if(flag.current === false)
        {
            // Create PaymentIntent as soon as the page loads
            axios.post(`${BACKEND_URL}payment/stripe-client-secret`, {
                stripeSecretKey: USE_LIVE_MODE ? STRIPE_SECRET_LIVE_KEY : STRIPE_SECRET_TEST_KEY,
                id_currency: id_currency,
                amount: item['price']
            })
            .then((response) => {
                setClientSecret(response.data.clientSecret)
                setAmountToPay(response.data.amount_text)
                let tab = options['success_url'].split('?');
                let success_url= tab[0];
                let query= tab[1] ?? '';
                if(query.length >0) query +='&';
                query += 'provider=3&paymentId=' + response.data.paymentId +'&id_currency='+ id_currency;
                success_url += '?' + query;
                setSuccessUrl(success_url);
            })
        }
        return () => flag.current = true;
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const elementOptions = {
        clientSecret,
        appearance,
    };

    return (
        <div className="Stripe">
        {clientSecret && (
            <Elements options={elementOptions} stripe={stripePromise}>
                <CheckoutForm success_url={successUrl} amount_to_pay={amountToPay} />
            </Elements>
        )}
        </div>
    );
};

export default Stripe;