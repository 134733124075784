import axios from "axios";
export const store = async (reservations) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/store`, reservations);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la reservation", error);
    throw error; // Rejeter l'erreur pour que l'appelant puisse la gérer
  }
};
