import { IoFastFoodSharp } from "react-icons/io5";
import { FaUser, FaPhone, FaPowerOff } from "react-icons/fa6";
import { MdEventBusy, MdEmail } from "react-icons/md";
import { IoMdPin, IoIosLock } from "react-icons/io";
import { Await, Link, useNavigate } from "react-router-dom";
import pp from "../../ASSETS/Image/african-teenage-girl-portrait-happy-smiling-face.jpg";
import Loading from '../../COMPONENTS/Loading';
import React, { useState, useEffect } from 'react';
import Header from "../../COMPONENTS/Header";
import { profil } from "../../API/profil/profil";
import { accountService } from "../../services/Account.service";
import { update } from "../../API/Update/update";
import { logo } from "../../API/Logo/logo";
import {  toast, ToastContainer } from "react-toastify";

function Profil() {
  const [loading, setLoading] = useState(true);
  const [profils, setProfils] = useState([]);
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    username: "",
    mail: "",
    phone: "",
    adresse: "",
    password: ""
  });
  const [message, setMessage] = useState('');
  const id = accountService.getId();
  let navigate = useNavigate();

  const logout = () => {
    accountService.logout();
    navigate('/');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    profil(id).then((response) => {
      setProfils(response.profils);
      setFormData({
        lastname: response.profils?.LastName || "",
        firstname: response.profils?.FirstName || "",
        username: response.profils?.username || "",
        mail: response.profils?.Email || "",
        phone: response.profils?.Phones || "",
        adresse: response.profils?.Adress || "",
        password: ""
      });
    }).catch(error => {
      console.log("Erreur lors de la récupération " + error);
    });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await update(id, formData);
      if(response.status){
        setProfils(response.newProfils)
        setMessage(response.message || 'Utilisateur mis à jour avec succès');
        toast.success(response.message || 'Utilisateur mis à jour avec succès');
        setIsSubmitting(true)
      }
     
    } catch (error) {
      setMessage('Erreur lors de la mise à jour de l\'utilisateur');
      console.error(error); // Affichez l'erreur pour le débogage
      
      // Si l'erreur a une réponse et contient des messages d'erreur spécifiques, les afficher
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            if (Array.isArray(errors[key])) {
              errors[key].forEach(errMsg => {
                toast.error(errMsg);
              });
            } else {
              toast.error(errors[key]);
            }
          }
        }
      } else {
        toast.error('Erreur lors de la mise à jour de l\'utilisateur');
      }
    }
  };

  const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
        <ToastContainer/>
          <div className="flex flex-row bg-[#F7F7F7] h-screen">
            <div className="w-2/3 barre_scroll">
              <div className="flex s:justify-between s:flex-row xs:flex-col s:h-20 xs:h-32 s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md s:px-6 xs:px-2 z-10 fixed w-2/3 ">
                <div className="text-3xl">
                     <img className="w-40 h-10  object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
                </div>
                <div className="text-2xl font-bold">ON-SITE</div>
              </div>
              <div className="p-6 s:text-2xl xs:text-lg text-[#4E4E4E] mt-20">
                Mon compte
              </div>
              <div className="p-6 mb-10">
                <div className="grid md:grid-cols-2 xs:grid-cols-1 gap-y-6">
                  <div className="flex s:flex-row xs:flex-col bg-white s:w-[400px] shadow items-center space-x-5 py-6 xs:px-3 s:px-4 s:py-4 rounded-lg h-full">
                    <img
                      src={profils?.Profils ? `data:image/png;base64,${profils.Profils}` : pp}
                      alt="pp"
                      className="w-20 h-20 rounded-full border-4 border-black"
                    />
                    <div className="flex flex-col items-center">
                      <div className="text-xl font-semibold">
                        {profils ? profils.LastName + ' ' + profils.FirstName : 'Kouakou Marie ange'}
                      </div>
                      <div className="font-light">{profils ? profils['Email'] : 'kouakou@gmail.com'}</div>
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">ID Employé :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.az_id : 'ED435618901'}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Fonction :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.fr_Positions : 'Caissieère'}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Adresse :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.Adress : "Abidjan, Côte d'ivoire"}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Numéro d'urgence :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.Phones : '+225 05 34 54 34 34'}
                    </div>
                  </div>

                  <div className="text-lg">
                    <div className="text-[#4E4E4E] px-4">Nom d'utilisateur :</div>
                    <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                      {profils ? profils.username : 'Kouakoumarie32'}
                    </div>
                  </div>
                </div>

                <div className="h-full mt-14 bg-white p-4 flex flex-col rounded shadow space-y-4">
                  <div className="text-xl font-semibold text-[#D19E06]">
                    Règlement intérieur & procédure d'urgence
                  </div>
                  <div className="text-lg">
                    {/* Texte du règlement intérieur */}
                  </div>
                </div>

                <button onClick={logout} className="bg-[#D7D7D7] shadow w-full mt-14 h-9 py text-lg rounded font-medium">
                  Déconnexion
                </button>
              </div>
            </div>

            <div className="w-1/3 bg-white barre_scroll">
              <Header></Header>
              <div className="md:mt-24 xs:mt-80 pb-2 pl-6 font-medium xs:text-base md:text-xl">
                Modifier mon compte
              </div>
              <div className="w-[340px] pl-6">
                Veuillez remplir le formulaire ou le champ concerné pour modifier vos informations
              </div>
              <form onSubmit={handleSubmit}>
                <div className="p-6 flex flex-col space-y-5">
                  {/* Nom */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3">
                    <FaUser className="text-[#C2C2C2]" />
                    <input
                      type="text"
                      className="w-[300px] px-2 h-10 outline-none"
                      name="lastname"
                      placeholder="Nom"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Prénom */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <FaUser className="text-[#C2C2C2]" />
                    <input
                      type="text"
                      className="w-[300px] px-2 h-10 outline-none"
                      placeholder="Prénom"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Nom d'utilisateur */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <FaUser className="text-[#C2C2C2]" />
                    <input
                      type="text"
                      className="w-[300px] px-2 h-10 outline-none"
                      placeholder="Nom d'utilisateur"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Email */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <MdEmail className="text-[#C2C2C2]" />
                    <input
                      type="email"
                      className="w-[300px] px-2 h-10 outline-none"
                      placeholder="Adresse mail"
                      name="mail"
                      value={formData.mail}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Numéro */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <FaPhone className="text-[#C2C2C2]" />
                    <input
                      type="tel"
                      className="w-[300px] px-2 h-10 outline-none"
                      placeholder="Numéro d'urgence"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Adresse */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <IoMdPin className="text-[#C2C2C2]" />
                    <input
                      type="text"
                      className="w-[300px] px-2 h-10 outline-none"
                      placeholder="Adresse"
                      name="adresse"
                      value={formData.adresse}
                      onChange={handleChange}
                    />
                  </div>
                  {/* Mot de passe */}
                  <div className="border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                    <IoIosLock className="text-[#C2C2C2]" />
                    <input
                      type="password"
                      className="w-[300px] px-2 h-10 outline-none"
                      name="password"
                      placeholder="Nouveau mot de passe"
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    disabled={isSubmitting}
                    className={`btn ${isSubmitting ? 'bg-gray-500' : 'bg-black'}  text-white w-[340px] h-10 rounded-md`}
                  >
                    Modifier
                  </button>

                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Profil;
