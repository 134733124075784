import axios from "axios";

export const all = async ()=>{
    try{
        const response  = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/all`);
        return response.data;
    }catch(error){
        console.log("Erreur lors de l'appel a l'API ",error)
        throw error;
    }
}